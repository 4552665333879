import * as documentationRequestStatuses from 'constants/enums/documentationRequestStatuses'

export default {
  [documentationRequestStatuses.NEW]: 'Nová ',
  [documentationRequestStatuses.COMPLETION]: 'K dokončení',
  [documentationRequestStatuses.GENERATION]: 'Generování',
  [documentationRequestStatuses.PROCESSING]: 'Ve spracovaní',
  [documentationRequestStatuses.CLOSED]: 'Uzavřená',
  [documentationRequestStatuses.CANCELLED]: 'Zrušená',
}
