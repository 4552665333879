import {compact} from 'lodash'

export const API = '/api'
export const API_HEALTH_CHECK = '/healthz'
export const API_UPDATES = '/updates'

export const API_DOCS = '/api.json'
export const API_SWAGGER = '/docs'
export const API_ME = '/me'

export const API_LOGIN = '/auth/login'

export const API_GET_COMPANY_INFO = '/company-info'

export const API_GENERATE = 'generate'
export const API_CONFIRM = 'confirm'
export const API_SEND = 'send'
export const API_FINISH = 'finish'

export const API_SIGNI = 'signi'

export const generateResourcePath = (...args: (string | number | null)[]) =>
  `/${compact(args).join('/')}`
