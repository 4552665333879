import type {
  QueryFunctionContext,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query'
import {
  useMutation,
  onlineManager,
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import invalidationMap from 'common/invalidationMap'
import type {Resource} from 'constants/resources'
import * as resources from 'constants/resources'
import {generateResourcePath} from 'constants/routes'
import * as routes from 'constants/routes'
import {debounce} from 'lodash'
import {memoize} from 'lodash-es'
import type {FC, ReactNode} from 'react'
import {useCallback, useEffect, useState} from 'react'
import {env} from '../../../../env'
import type {Options, SSEOptions} from '../utils/api'
import {api, sse} from '../utils/api'

export type ErrorResponse = {
  status: number
  statusText: string
  errorCode?: string
  message: string
}

export class FrontendError extends Error {
  data: Partial<ErrorResponse>
}

const client = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
      retry: (failureCount, error: FrontendError) => {
        const status = error?.data?.status
        if (status && status < 500) return false
        return failureCount < 3
      },
      staleTime: Infinity, // Invalidated using SSE
      gcTime: Infinity,
    },
  },
})

type ApiProviderProps = {
  children: ReactNode
}

export const ApiProvider: FC<ApiProviderProps> = ({children}) => {
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

type OneQuery = {
  resource: Resource
  id: string
}

// Queries

const createOneQueryKey = ({resource, id}: OneQuery) => {
  return [resource, {params: {id}}] as const
}

const createOneQueryFn = <TValue,>(
  ctx: QueryFunctionContext<ReturnType<typeof createOneQueryKey>>,
) => {
  const [resource, options] = ctx.queryKey
  const route = generateResourcePath(resource, ':id')

  return api<TValue>('GET', route, {
    signal: ctx.signal,
    ...options,
  })
}

export const createOneQuery = <TValue,>({resource, id}: OneQuery) => {
  const queryKey = createOneQueryKey({resource, id})

  return {
    queryKey,
    queryFn: createOneQueryFn<TValue>,
    placeholderData: keepPreviousData,
  } satisfies UseQueryOptions
}

type ListQuery = {
  resource: Resource
  query?: Options['query']
}

const createListQueryKey = ({resource, query}: ListQuery) => {
  if (!query) return [resource] as const

  const options = {
    query,
  } satisfies Options

  return [resource, options] as const
}

const createListQueryFn = <TValue,>(
  ctx: QueryFunctionContext<ReturnType<typeof createListQueryKey>>,
) => {
  const [resource, options] = ctx.queryKey
  const route = generateResourcePath(resource)

  return api<TValue>('GET', route, {
    signal: ctx.signal,
    ...options,
  })
}

export const createListQuery = <TValue,>({resource, query}: ListQuery) => {
  const queryKey = createListQueryKey({
    resource,
    query,
  })

  return {
    queryKey,
    queryFn: createListQueryFn<TValue>,
    placeholderData: keepPreviousData,
  } satisfies UseQueryOptions
}

export const invalidateResource = async (
  resource: Resource,
  queryClient: QueryClient,
) => {
  await queryClient.invalidateQueries({
    queryKey: createListQueryKey({resource}),
    type: 'all',
    refetchType: 'active',
  })

  const dependentAppResources = invalidationMap[resource]

  await Promise.all(
    dependentAppResources.map(async (dependentAppResource) => {
      await queryClient.invalidateQueries({
        queryKey: createListQueryKey({resource: dependentAppResource}),
        type: 'all',
        refetchType: 'active',
      })
    }),
  )
}

type SupportedMutationOptions = Partial<
  Pick<UseMutationOptions, 'retry' | 'retryDelay' | 'meta' | 'throwOnError'>
>

type CreateResource = {
  resource: Resource
  config?: SupportedMutationOptions
}

type CreateResponse = {
  id: string
}

export const useCreateResource = ({resource, config}: CreateResource) => {
  const route = generateResourcePath(resource)

  return useMutation({
    mutationFn: (options: Options) => {
      return api<CreateResponse>('POST', route, options)
    },
    mutationKey: ['POST', route],
    throwOnError: undefined,
    ...config,
  })
}

type UpdateResource = {
  resource: Resource
  id: number
  config?: SupportedMutationOptions
}

type UpdateResponse = {
  id: number
}

export const useUpdateResource = ({resource, id, config}: UpdateResource) => {
  const route = generateResourcePath(resource, id || ':resourceId')

  return useMutation({
    mutationFn: (options: Options) => {
      return api<UpdateResponse>('PUT', route, options)
    },
    mutationKey: ['PUT', route],
    throwOnError: undefined,
    ...config,
  })
}

type DeleteResource = {
  resource: Resource
  id?: number
  config?: SupportedMutationOptions
}

type DeleteResponse = {
  id: number
}

export const useDeleteResource = ({resource, id, config}: DeleteResource) => {
  const route = generateResourcePath(resource, id || ':resourceId')
  return useMutation({
    mutationFn: (id?: string | number) => {
      const params = {resourceId: String(id)}

      return api<DeleteResponse>('DELETE', route, {params})
    },
    mutationKey: ['DELETE', route],
    throwOnError: undefined,
    ...config,
  })
}

export const useSSE = (route: string, options?: SSEOptions) => {
  const [_error, setError] = useState<Error | undefined>()
  useEffect(() => {
    const abortController = new AbortController()

    sse('POST', route, {
      ...options,
      signal: abortController.signal,
    }).catch((err) => {
      // Hack to enable react to catch the error in ErrorBoundary
      setError(() => {
        throw err
      })
    })
    return () => {
      abortController.abort()
    }
  }, [route, options])
}

export const useCompanyInfo = <TValues,>() => {
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState<TValues | undefined>()

  // UseCallback can't be inside memoize function because it could cause multiple unnecessary requests
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCompanies = useCallback(
    memoize(async (value: string) => {
      return await api<TValues>('GET', routes.API_GET_COMPANY_INFO, {
        query: {
          // Even though, it's a string, it's parsed as a number on the backend.
          // So we need to stringify it here to successfully validate it on the backend.
          companyNumber: JSON.stringify(value),
        },
      })
    }),
    [],
  )

  // UseCallback can't determine dependencies when wrapped in debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchCompanies = useCallback(
    debounce(async (value: string, enabled?: boolean) => {
      setLoading(true)
      if (!enabled) {
        setLoading(false)
        setCompanies(undefined)
        return
      }
      const res = await loadCompanies(value)
      setCompanies(res.data)
      setLoading(false)
    }, 800),
    [loadCompanies],
  )

  return {companies, searchCompanies, loading}
}

export const useGenerateContract = () => {
  const route = routes.generateResourcePath(
    resources.REQUEST_APPROVAL_CONTRACTS,
    routes.API_GENERATE,
  )

  return useMutation({
    mutationFn: (options: Options) => {
      return api<CreateResponse>('POST', route, options)
    },
    mutationKey: ['POST', route],
    throwOnError: undefined,
  })
}

export const useSendRequestApproval = (id: number) => {
  const route = routes.generateResourcePath(
    resources.REQUEST_APPROVALS,
    id,
    routes.API_SEND,
  )

  return useMutation({
    mutationFn: (options: Options) => {
      return api<CreateResponse>('PUT', route, options)
    },
    mutationKey: ['PUT', route],
    throwOnError: undefined,
  })
}

export const useConfirmContract = (id: number) => {
  const route = routes.generateResourcePath(
    resources.REQUEST_APPROVAL_CONTRACTS,
    id,
    routes.API_CONFIRM,
  )

  return useMutation({
    mutationFn: (options: Options) => {
      return api<CreateResponse>('PUT', route, options)
    },
    mutationKey: ['PUT', route],
    throwOnError: undefined,
  })
}

export const useDeclineContract = (id: number) => {
  const route = routes.generateResourcePath(
    resources.REQUEST_APPROVAL_CONTRACTS,
    id,
    routes.API_FINISH,
  )

  return useMutation({
    mutationFn: (options: Options) => {
      return api<CreateResponse>('DELETE', route, options)
    },
    mutationKey: ['DELETE', route],
    throwOnError: undefined,
  })
}

export const useFinishContract = (id: number) => {
  const route = routes.generateResourcePath(
    resources.REQUEST_APPROVAL_CONTRACTS,
    id,
    routes.API_FINISH,
  )

  return useMutation({
    mutationFn: (options: Options) => {
      return api<CreateResponse>('PUT', route, options)
    },
    mutationKey: ['PUT', route],
    throwOnError: undefined,
  })
}

export const useOnline = () => {
  const [online, setOnline] = useState(onlineManager.isOnline())
  useEffect(() => {
    return onlineManager.subscribe(() => {
      setOnline(onlineManager.isOnline())
    })
  }, [])
  return online
}

export const getFileLink = (id: string) => {
  return (
    env.publicServerUrl + routes.API + generateResourcePath(resources.FILES, id)
  )
}
