import * as roles from 'constants/enums/roles'

type User = {
  id: string
  role: roles.Role | null
}

type RequestApproval = {
  id: number
  createdById: string
  assistantId: string | null
}

export const isAdmin = (user: User) => user.role === roles.A

export const isUserRequestApproval = (user: User) =>
  user.role &&
  [roles.VP, roles.ZVP, roles.P, roles.APO, roles.J, roles.UK].includes(
    user.role,
  )

export const canListRequestApprovals = (user: User) =>
  isAdmin(user) || isUserRequestApproval(user)

export const isCreatedBy = (user: User, requestApproval: RequestApproval) =>
  user.id === requestApproval.createdById

export const isVPBoss = (user: User) =>
  user.role && [roles.VP, roles.ZVP].includes(user.role)

export const isRequestApprovalLegal = (
  user: User,
  requestApproval: RequestApproval,
) =>
  (user.role && [roles.VP, roles.ZVP, roles.P].includes(user.role)) ||
  (user.role === roles.APO && user.id === requestApproval.assistantId)

export const canCreateRequestApproval = (user: User) =>
  isAdmin(user) || isUserRequestApproval(user)

export const canReadRequestApproval = (
  user: User,
  requestApproval: RequestApproval,
) =>
  isAdmin(user) ||
  isRequestApprovalLegal(user, requestApproval) ||
  isCreatedBy(user, requestApproval) ||
  user.role === roles.J

export const canUpdateRequestApproval = (
  user: User,
  requestApproval: RequestApproval,
) =>
  isAdmin(user) ||
  (canReadRequestApproval(user, requestApproval) && user.role !== roles.J)

export const canCancelRequestApproval = (user: User) =>
  isAdmin(user) || isVPBoss(user)

export const canConfirmRequestApprovalContract = (
  user: User,
  requestApproval: RequestApproval,
) => isAdmin(user) || isVPBoss(user) || isCreatedBy(user, requestApproval)

export const canSendRequestApproval = (user: User) =>
  isAdmin(user) || isVPBoss(user)

export const canDeclineRequestApprovalContract = (
  user: User,
  requestApproval: RequestApproval,
) => isAdmin(user) || isRequestApprovalLegal(user, requestApproval)

export const canFinishRequestApprovalContract = (
  user: User,
  requestApproval: RequestApproval,
) => isAdmin(user) || isRequestApprovalLegal(user, requestApproval)

export const canGenerateContract = (
  user: User,
  requestApproval: RequestApproval,
) => isAdmin(user) || isRequestApprovalLegal(user, requestApproval)

export const canUpdateRequestApprovalCompanies = (
  user: User,
  requestApproval: RequestApproval,
) =>
  canUpdateRequestApproval(user, requestApproval) &&
  (isRequestApprovalLegal(user, requestApproval) || isAdmin(user))

export const canConfirmRequestApprovalVP = (user: User) =>
  user.role && (isAdmin(user) || [roles.VP, roles.ZVP].includes(user.role))

export const canConfirmRequestApprovalUK = (
  user: User,
  requestApproval: RequestApproval,
) =>
  user.role &&
  (isAdmin(user) ||
    (user.role === roles.UK && isCreatedBy(user, requestApproval)))
export const isUserRequestDocumentation = (_user: User) => true
export const canListRequestDocumentations = (user: User) =>
  isAdmin(user) || isUserRequestDocumentation(user)
export const canCreateRequestDocumentation = (user: User) =>
  isAdmin(user) || isUserRequestApproval(user)
export const canUpdateRequestDocumentation = (user: User) => {
  return isAdmin(user) || isUserRequestDocumentation(user)
}

export const isUserContractTemplate = (user: User) =>
  user.role && [roles.VP, roles.ZVP].includes(user.role)

export const canCreateContractTemplate = (user: User) =>
  isAdmin(user) || isUserContractTemplate(user)

export const canListContractTemplate = (user: User) =>
  isAdmin(user) || isUserContractTemplate(user)

export const canUpdateContractTemplate = (user: User) =>
  isAdmin(user) || isUserContractTemplate(user)
