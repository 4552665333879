import allType from './allType'
import approvalContractTypes from './approvalContractTypes'
import clientLegalForms from './clientLegalForms'
import contractStatuses from './contractStatuses'
import documentationChangeSeverity from './documentationChangeSeverity'
import documentationContractStatuses from './documentationContractStatuses'
import documentationContractTypes from './documentationContractTypes'
import documentationRequestStatuses from './documentationRequestStatuses'
import processes from './processes'
import requestApprovalStatuses from './requestApprovalStatuses'
import roles from './roles'
import signer from './signer'
import signingMethods from './signingMethods'

export const ALL_TYPES = 'allTypes'
export const CLIENT_LEGAL_FORMS = 'clientLegalForms'
export const CONTRACT_STATUSES = 'contractStatuses'
export const APPROVAL_CONTRACT_TYPES = 'approvalContractTypes'
export const DOCUMENTATION_CHANGE_SEVERITY = 'documentationChangeSeverity'
export const DOCUMENTATION_CONTRACT_STATUSES = 'documentationContractStatuses'
export const DOCUMENTATION_CONTRACT_TYPES = 'documentationContractTypes'
export const DOCUMENTATION_REQUEST_STATUSES = 'documentationRequestStatuses'
export const PROCESSES = 'processes'
export const REQUEST_APPROVAL_STATUSES = 'requestApprovalStatuses'
export const ROLES = 'roles'
export const SIGNER = 'signer'
export const SIGNING_METHODS = 'signingMethods'

const enums = {
  [ALL_TYPES]: allType,
  [CONTRACT_STATUSES]: contractStatuses,
  [APPROVAL_CONTRACT_TYPES]: approvalContractTypes,
  [DOCUMENTATION_CHANGE_SEVERITY]: documentationChangeSeverity,
  [DOCUMENTATION_CONTRACT_STATUSES]: documentationContractStatuses,
  [CLIENT_LEGAL_FORMS]: clientLegalForms,
  [DOCUMENTATION_CONTRACT_TYPES]: documentationContractTypes,
  [DOCUMENTATION_REQUEST_STATUSES]: documentationRequestStatuses,
  [PROCESSES]: processes,
  [REQUEST_APPROVAL_STATUSES]: requestApprovalStatuses,
  [ROLES]: roles,
  [SIGNER]: signer,
  [SIGNING_METHODS]: signingMethods,
} as const

export type Enums = typeof enums
export type Enum = keyof Enums

export default enums
