import {extendApi} from '@anatine/zod-openapi'
import {ALL_APPROVAL_CONTRACT_TYPES} from 'constants/enums/approvalContractTypes'
import {ALL_CONTRACT_SIGNINGS} from 'constants/enums/contractSignings'
import {ALL_CONTRACT_STATUSES} from 'constants/enums/contractStatuses'
import {ALL_DOCUMENTATION_CHANGE_SEVERITY} from 'constants/enums/documentationChangeSeverity'
import {ALL_DOCUMENTATION_CONTRACT_STATUSES} from 'constants/enums/documentationContractStatuses'
import {ALL_DOCUMENTATION_CONTRACT_TYPES} from 'constants/enums/documentationContractTypes'
import {ALL_DOCUMENTATION_REQUEST_STATUSES} from 'constants/enums/documentationRequestStatuses'
import {ALL_PROCESSES} from 'constants/enums/processes'
import {ALL_REQUEST_APPROVAL_STATUSES} from 'constants/enums/requestApprovalStatuses'
import {ALL_ROLES} from 'constants/enums/roles'
import {ALL_SIGNER} from 'constants/enums/signer'
import {ALL_SIGNING_METHODS} from 'constants/enums/signingMethods'
import {ALL_RESOURCES} from 'constants/resources'
import {z} from 'zod'
import {createEnum} from './types'

// General
export const email = (example = 'example@example.com') =>
  extendApi(z.string().email(), {examples: [example]})

export const telephone = () =>
  extendApi(z.string(), {examples: ['+421903123456']})

export const date = () =>
  extendApi(
    z
      .string()
      .trim()
      .regex(/^\d{4}-(0[1-9]|1[0-2])-([0][1-9]|[12][0-9]|[3][01])$/),
    {examples: ['1980-03-24']},
  )

export const requestApprovalStatus = () =>
  createEnum(ALL_REQUEST_APPROVAL_STATUSES)
export const signingMethod = () => createEnum(ALL_SIGNING_METHODS)
export const contractType = () => createEnum(ALL_APPROVAL_CONTRACT_TYPES)
export const contractStatus = () => createEnum(ALL_CONTRACT_STATUSES)
export const contractSigning = () => createEnum(ALL_CONTRACT_SIGNINGS)
export const documentationChangeSeverity = () =>
  createEnum(ALL_DOCUMENTATION_CHANGE_SEVERITY)
export const documentationContractStatuses = () =>
  createEnum(ALL_DOCUMENTATION_CONTRACT_STATUSES)
export const documentationRequestStatuses = () =>
  createEnum(ALL_DOCUMENTATION_REQUEST_STATUSES)
export const signer = () => createEnum(ALL_SIGNER)
export const contractTypeApproval = () =>
  createEnum(ALL_APPROVAL_CONTRACT_TYPES)
export const contractTypeDocumentation = () =>
  createEnum(ALL_DOCUMENTATION_CONTRACT_TYPES)
export const processes = () => createEnum(ALL_PROCESSES)
export const roles = () => createEnum(ALL_ROLES)
export const resource = () => createEnum(ALL_RESOURCES)

export type Resource = z.infer<ReturnType<typeof resource>>
