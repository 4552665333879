export const NEW = 'new'
export const COMPLETION = 'completion'
export const GENERATION = 'generation'
export const PROCESSING = 'processing'
export const CLOSED = 'closed'
export const CANCELLED = 'cancelled'

export const ALL_DOCUMENTATION_REQUEST_STATUSES = [
  NEW,
  COMPLETION,
  GENERATION,
  PROCESSING,
  CLOSED,
  CANCELLED,
] as const

export type DocumentationRequestStatuses =
  (typeof ALL_DOCUMENTATION_REQUEST_STATUSES)[number]
